import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Link from 'gatsby-link'
import get from 'lodash/get'
import Layout from '../components/layoutNewsItem'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import BoilerplateAbout from '../components/boilerplateAbout'
import BoilerplateEntities from '../components/boilerplateEntities'
import MediaContact from '../components/mediaContact'
import Related from '../components/newsRelated'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { StickyShareButtons } from 'sharethis-reactjs'

function filterByPublishTo(arr, publishTo) {
  return arr.filter((obj) =>
    obj.publishTo.some((publish) => publish.url === publishTo)
  )
}

class NewsItemTemplate extends React.Component {
  render() {
    const news = get(this.props, 'data.contentfulNews')
    const heroImage = get(this.props, 'data.heroImage')
    const siteUrl = get(this.props, 'data.site.siteMetadata.siteUrl')
    const pageUrl = siteUrl + '/news/' + news.slug + '/'
    const bodyImageFullWidth = get(this.props, 'data.contentfulNews.bodyImageFullWidth')

    return (
      <>
        <Layout location={this.props.location}>
          <div id="sub-page-news">
            <SEO 
              title={news.metaTitle} 
              description={news.metaDescription} 
              url={pageUrl} 
              image={news.image ? 'https:' + news.image.file.url : siteUrl + heroImage.childImageSharp.gatsbyImageData.images.fallback.src}
            />
            <Hero
              class="intro-65"
              image={(news.imageHero && (news.imageHero.file.details.image.width > 1200)) ? news.imageHero.localFile.childImageSharp.gatsbyImageData : heroImage.childImageSharp.gatsbyImageData}
              title={news.title}
              subtitle=""
              type="news"
            />
          </div>

          {typeof window !== 'undefined' &&
            !/Android|webOS|iPhone|iPod|BlackBerry|BB|IEMobile|Windows Phone|Silk|Opera Mini/i.test(
              navigator.userAgent
            ) && (
              <div className="d-none d-sm-block">
                <StickyShareButtons
                  config={{
                    alignment: 'right', // alignment of buttons (left, right)
                    color: 'social', // set the color of buttons (social, white)
                    enabled: true, // show/hide buttons (true, false)
                    font_size: 16, // font size for the buttons
                    hide_desktop: false, // hide buttons on desktop (true, false)
                    labels: 'null', // button labels (cta, counts, null)
                    language: 'en', // which language to use (see LANGUAGES)
                    networks: [
                      // which networks to include (see SHARING NETWORKS)
                      'linkedin',
                      'facebook',
                      'twitter',                
                      'whatsapp',
                      'email',  
                    ],
                    padding: 12, // padding within buttons (INTEGER)
                    show_mobile: false, // show/hide the buttons on mobile (true, false)
                    show_toggle: false, // show/hide the toggle buttons (true, false)
                    size: 48, // the size of each button (INTEGER)
                    top: 85, // offset in pixels from the top of the page

                    // OPTIONAL PARAMETERS
                    url: pageUrl, // (defaults to current url)
                    description: news.title, // (defaults to og:description or twitter:description)
                    title: news.title, // (defaults to og:title or twitter:title)
                  }}
                />
              </div>
            )}

          <section className="bg-white">
            <MDBContainer>
              <MDBRow>
                <MDBCol lg="8">
                  <div>
                  {news.subtitle && 
                    <h2 className="font-alt font-w-400 text-opening-para">{news.subtitle}</h2>
                  }
                    {renderRichText(news.body, {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (node, children) => (
                          <p className="font-w-400 text-medium" style={{ whiteSpace: 'pre-wrap' }}>{children}</p>
                        ),
                        [BLOCKS.HEADING_6]: (node, children) => (
                          <p className="font-w-400 font-italic text-small text-center pb-3 pb-lg-5" style={{ whiteSpace: 'pre-wrap' }}>{children}</p>
                        ),
                        [INLINES.HYPERLINK]: (node, children) => 
                          node.data.uri.charAt(0) == '/' ? (
                            <Link to={node.data.uri}>{children}</Link>
                          ) : (
                            <a href={node.data.uri} className="effect" target="_blank">{children}</a>
                          ),
                        [INLINES.ASSET_HYPERLINK]: (node, children) => 
                        // console.log(node.data?.target?.gatsbyImageData?.images?.fallback?.src),  
                        // console.log(node.data.target.file.url),  
                        node.data.target.gatsbyImageData ? 
                            ((node.data.target.gatsbyImageData.images.fallback.src.charAt(0) == '/' && node.data.target.gatsbyImageData.images.fallback.src.substring(0,2) != '//') ? (
                              <Link to={node.data.target.gatsbyImageData.images.fallback.src}>{children}</Link>
                            ) : (
                              <a href={node.data.target.gatsbyImageData.images.fallback.src} className="effect" target="_blank">{children}</a>
                            )) : 
                        node.data.target.file && 
                            ((node.data.target.file.url.charAt(0) == '/' && node.data.target.file.url.substring(0,2) != '//') ? (
                              <Link to={node.data.target.file.url}>{children}</Link>
                            ) : (
                              <a href={node.data.target.file.url} className="effect" target="_blank">{children}</a>
                            )),
                        [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
                          return (
                            <GatsbyImage
                              image={node.data.target.gatsbyImageData}
                              alt={node.data.target.title}
                              className={`${bodyImageFullWidth ? "news-body-image-100" : "news-body-image"}`}
                            />
                          )
                        },
                      },
                    })}
                  </div>
                </MDBCol>

                <MDBCol lg="4" md="12">
                  <MediaContact
                    name={news.mediaContact.name}
                    email={news.mediaContact.email}
                    telephone={news.mediaContact.telephone}
                  />

                  {news.related != null && filterByPublishTo(news.related, siteUrl).length > 0 && 
                    <Related data={news.related} siteUrl={siteUrl} />
                  }
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>

          <section className="bg-light-blue">
            {news.divisions.map((division, index) => (
              <BoilerplateAbout about={division.boilerplate} key={index} />
            ))}

            {!news.suppressEntities &&
              <BoilerplateEntities />
            }
          </section>
        </Layout>
      </>
    )
  }
}
export default NewsItemTemplate

export const pageQuery = graphql`
  query NewsItemBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulNews(slug: { eq: $slug }) {
      slug
      metaTitle 
      metaDescription
      title
      subtitle
      date(formatString: "MMMM DD, YYYY")
      imageHero {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 90)
          }
        }
        file {
          details {
            image {
              width
            }
          }
        }
      }  
      bodyImageFullWidth    
      body {
        raw
        references {
          gatsbyImageData(
            quality: 90
            formats: [WEBP, AUTO]
            placeholder: BLURRED
          )
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            file {
              url
            }
          }
        }
      }
      divisions {
        boilerplate {
          raw
        }
      }
      mediaContact {
        name
        telephone
        email
      }
      suppressEntities 
      related {
        ... on ContentfulNews {
          slug
          title
          subtitle
          date
          publishTo {
            url
          }
        }
        ... on ContentfulRelatedOther {
          slug
          title
          date
          publishTo {
            url
          }
        }
      }
    }
    heroImage: file(name: { eq: "news-default" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`